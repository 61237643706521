@tailwind base;
@tailwind components;
@tailwind utilities;




  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');


body{
  font-family: 'Poppins', sans-serif;
}

.bg-custom {
    background-image: url('./assets/bg.png');
    background-size: cover;
    background-position: center;
  }
  

  .bg-it {
    background-image: url('./components//services/assets/1.png');
    background-size: cover;
    background-position: center;
  }
  

  .bg-application {
    background-image: url('./components//services/assets/9.png');
    background-size: cover;
    background-position: center;
  }

  .bg-document {
    background-image: url('./components//services/assets/8.png');
    background-size: cover;
    background-position: center;
  }


  .bg-strategy {
    background-image: url('./components//services/assets/7.png');
    background-size: cover;
    background-position: center;
  }


  .bg-fintech {
    background-image: url('./components//services/assets/6.png');
    background-size: cover;
    background-position: center;
  }


  .bg-cyber {
    background-image: url('./components//services/assets/5.png');
    background-size: cover;
    background-position: center;
  }


  .bg-design {
    background-image: url('./components//services/assets/4.png');
    background-size: cover;
    background-position: center;
  }

  
  .bg-cloud {
    background-image: url('./components//services/assets/3.png');
    background-size: cover;
    background-position: center;
  }

  
  .bg-software {
    background-image: url('./components//services/assets/2.png');
    background-size: cover;
    background-position: center;
  }
